import dynamic from 'next/dynamic'
import { LoadingIndicator } from 'atoms'

const DynamicOfficeMap = dynamic(
  () => import('./OfficeMap'),
  {
    ssr: false,
    loading: () => (<LoadingIndicator />)
  }
)

export default DynamicOfficeMap
