import classnames from 'classnames'
import {
  ForwardedRef, forwardRef, MouseEventHandler, ReactNode, useMemo,
} from 'react'

import Styles from './Button.module.sass'

type ButtonTheme = 'default'
  | 'blue'
  | 'green'
  | 'salomon'
  | 'gray'
  | 'link'
  | null
type ButtonType = 'default' | 'link' | 'submit' | null
type ButtonSize = 'small' | 'default' | 'large'
type LinkClickEventHandler = MouseEventHandler<HTMLAnchorElement> | undefined
type ButtonClickEventHandler = MouseEventHandler<HTMLButtonElement> |
undefined

export interface ButtonWrapperProps {
  type?: ButtonType;
  href?: string | null;
  rel?: string;
  target?: string;
  children?: ReactNode | null;
  className?: string,
  disabled?: boolean;
  onClick?: LinkClickEventHandler | ButtonClickEventHandler;
}

export interface ButtonProps extends ButtonWrapperProps {
  theme?: ButtonTheme;
  block?: boolean;
  size?: ButtonSize;
}

export type ButtonWrapperType = HTMLButtonElement | HTMLAnchorElement

const Button = forwardRef<ButtonWrapperType, ButtonProps>(({
  theme = 'default',
  size = 'default',
  href = '',
  block = false,
  disabled = false,
  className,
  children,
  ...restProps
}, ref) => {
  const buttonClasses = useMemo(() => classnames({
    [className || '']: true,
    [Styles.button]: true,
    [Styles.buttonBlue]: theme === 'blue',
    [Styles.buttonGreen]: theme === 'green',
    [Styles.buttonSalomon]: theme === 'salomon',
    [Styles.buttonGray]: theme === 'gray',
    [Styles.buttonLink]: theme === 'link',
    [Styles.buttonSmall]: size === 'small',
    [Styles.buttonLarge]: size === 'large',
    [Styles.buttonBlock]: block,
    [Styles.buttonDisabled]: disabled,
  }), [block, className, disabled, size, theme])

  return (
    <ButtonWrapper
      className={buttonClasses}
      disabled={disabled}
      ref={ref}
      href={href}
      {...restProps}
    >
      {children}
    </ButtonWrapper>
  )
})
Button.displayName = 'Button'

const ButtonWrapper = forwardRef<ButtonWrapperType, ButtonWrapperProps>(({
  type = 'default',
  href = '',
  onClick,
  children,
  ...restProps
}, ref) => {
  if (type === 'submit') {
    return (
      <button
        onClick={onClick as ButtonClickEventHandler}
        type="submit"
        ref={ref as ForwardedRef<HTMLButtonElement>}
        {...restProps}
      >
        {children}
      </button>
    )
  } else if (type === 'link') {
    return (
      <a
        onClick={onClick as LinkClickEventHandler}
        href={href || ''}
        ref={ref as ForwardedRef<HTMLAnchorElement>}
        {...restProps}
      >
        {children}
      </a>
    )
  } else {
    return (
      <button
        onClick={onClick as ButtonClickEventHandler}
        type="button"
        ref={ref as ForwardedRef<HTMLButtonElement>}
        {...restProps}
      >
        {children}
      </button>
    )
  }
})
ButtonWrapper.displayName = 'ButtonWrapper'

export default Button
