import { FC, ReactNode } from 'react'
import { GrClose } from 'react-icons/gr'
import { isPresent } from 'utils'
import Styles from './ModalHeader.module.sass'

interface ModalHeaderProps {
  title?: ReactNode | string | null,
  onClickClose: () => void,
}

const ModalHeader: FC<ModalHeaderProps> = ({
  title = null,
  onClickClose = () => undefined,
}) => (
  <header className={`modal-header ${Styles.header}`}>
    <div className={Styles.title}>
      {isPresent(title) && title}
    </div>
    <button
      className={Styles.button}
      onClick={(e) => {
        e.preventDefault()
        onClickClose()
      }}
    >
      <GrClose />
    </button>
  </header>
)

export default ModalHeader
