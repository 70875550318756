import dynamic from 'next/dynamic'

const CookieConsent = dynamic(
  () => import('./CookieConsent'),
  {
    loading: () => null,
    ssr: false, // This line is important. It's what prevents server-side render
  }
)

export default CookieConsent
