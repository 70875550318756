import compact from 'lodash/compact'
import isObject from 'lodash/isObject'
import includes from 'lodash/includes'
import without from 'lodash/without'
import {
  FC,
  ReactNode,
  useCallback,
  useMemo,
  useState,
} from 'react'
import { isPresent } from 'utils'
import {
  Provider,
  DEFAULT_DATA,
} from './CollapsibleContext'
import { CollapsibleContext, CollapsibleGroup } from './Types'

interface CollapsibleContextProviderProps {
  children: ReactNode
}

const CollapsibleContextProvider: FC<CollapsibleContextProviderProps> = ({
  children
}) => {
  const [collapsibles, setCollapsibles] = useState(
    DEFAULT_DATA.collapsibles
  )

  //
  // Toggle an item
  const toggle = useCallback((groupId: string, itemId: string) => {
    if (!isPresent(groupId)) return
    if (!isPresent(itemId)) return

    setCollapsibles((data) => {
      const group: CollapsibleGroup = data[groupId]
      if (!group || !isObject(group)) return data

      let itemsOpen = [...group.itemsOpen]

      if (includes(itemsOpen, itemId)) {
        itemsOpen = without(itemsOpen, itemId)
      } else if (group.multiOpen) {
        itemsOpen.push(itemId)
      } else {
        itemsOpen = [itemId]
      }

      itemsOpen = compact(itemsOpen)

      return {
        ...data,
        [groupId]: {
          ...group,
          itemsOpen
        }
      }
    })
  }, [])

  //
  // Collapse item
  const collapse = useCallback((groupId: string, itemId: string) => {
    if (!isPresent(groupId)) return
    if (!isPresent(itemId)) return

    const group: CollapsibleGroup = collapsibles[groupId]

    if (includes(group?.itemsOpen || [], itemId)) toggle(groupId, itemId)
  }, [collapsibles, toggle])

  //
  // Uncollapse item
  const uncollapse = useCallback((groupId: string, itemId: string) => {
    if (!isPresent(groupId)) return
    if (!isPresent(itemId)) return

    const group: CollapsibleGroup = collapsibles[groupId]

    if (!includes(group?.itemsOpen || [], itemId)) toggle(groupId, itemId)
  }, [collapsibles, toggle])

  //
  // Ensure a group inside the data structure
  const ensureGroup = useCallback((groupId: string) => {
    if (!isPresent(groupId)) return

    setCollapsibles((data) => {
      if (isObject(data[groupId])) return data

      return {
        ...data,
        [groupId]: {
          id: groupId,
          multiOpen: false,
          itemsOpen: []
        }
      }
    })
  }, [])

  //
  // Build context data
  const data: CollapsibleContext = useMemo(() => ({
    ...DEFAULT_DATA,
    collapsibles,
    toggle,
    collapse,
    uncollapse,
    ensureGroup,
  } as CollapsibleContext), [
    collapse,
    collapsibles,
    ensureGroup,
    toggle,
    uncollapse
  ])

  //
  // Render component
  return (
    <Provider value={data}>
      {children}
    </Provider>
  )
}

export default CollapsibleContextProvider
