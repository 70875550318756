import type { StaticImageData } from 'next/image'
import { ElementType, FC } from 'react'
import {
  CollapsibleItem,
  CollapsibleItemProps,
} from 'services/Collapsible'
import TopicLabel, { TopicLabelColor } from './TopicLabel'
import Styles from './Topic.module.sass'

interface TopicProps extends CollapsibleItemProps {
  /**
   * Center label as string
   */
  label: string;

  /**
   * Image to be displayed left
   */
  labelImage?: StaticImageData;

  /**
   * Color of label
   */
  labelColor?: TopicLabelColor;

  /**
   * Tag of the label to be used
   */
  labelAs?: ElementType;
}

const Topic: FC<TopicProps> = ({
  groupId,
  id,
  label,
  labelImage,
  labelColor,
  labelAs,
  content,
  className,
}) => (
  <CollapsibleItem
    id={id}
    groupId={groupId}
    content={<div className={Styles.content}>{content}</div>}
    label={<TopicLabel
      groupId={groupId}
      id={id}
      text={label}
      labelImage={labelImage}
      labelColor={labelColor}
      labelAs={labelAs}
    />}
    className={`${Styles.wrapper} ${className || ''}`}
  />
)

export default Topic
