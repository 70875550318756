import isFunction from 'lodash/isFunction'
import NextLink, { LinkProps as NextLinkProps } from 'next/link'
import {
  AnchorHTMLAttributes, FC, MouseEventHandler, useCallback, useMemo,
} from 'react'

export type ClickHandler = MouseEventHandler<HTMLAnchorElement>

export interface LinkProps extends AnchorHTMLAttributes<unknown> {
  nextLinkProps?: NextLinkProps;

  /**
   * Setting the value to true will add a target blank and
   * we might want to track outbound links in analytics.
   * To do so, pass in true here.
   * https://serverfault.com/questions/61574/possible-to-get-exit-url-in-google-analytics
   * https://support.google.com/analytics/answer/1136920?hl=en#zippy=%2Cin-this-article
   */
  external?: boolean;
}

const Link: FC<LinkProps> = ({
  nextLinkProps,
  href,
  external,
  children,
  onClick: onOriginalClick,
  ...restProps
}) => {
  const onClick = useCallback<ClickHandler>((event) => {
    if (external) {
      // TODO: track external
      console.log('TRACK EXTERNAL', href)
    }

    if (isFunction(onOriginalClick)) {
      onOriginalClick(event)
    }
  }, [href, onOriginalClick, external])

  const additionalProps = useMemo(() => {
    const p: AnchorHTMLAttributes<unknown> = {}
    if (external) p.target = '_blank'
    return p
  }, [external])

  return href ? (
    <NextLink
      href={href}
      {...nextLinkProps}
      {...restProps}
      {...additionalProps}
      onClick={onClick}
      role="link"
      tabIndex={0}
    >
      {children}
    </NextLink>
  ) : null
}


export default Link
