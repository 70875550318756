import type { BlogEntry } from 'BlogEntries'
import { Button } from 'atoms'
import { format } from 'date-fns'
import Image from 'next/image'
import Link from 'next/link'
import { FC, useMemo } from 'react'
import { HiChevronDoubleRight } from 'react-icons/hi'

import Styles from './BlogListItem.module.sass'

interface BlogListItemProps {
  blogEntry: BlogEntry
}

const BlogListItem: FC<BlogListItemProps> = ({
  blogEntry,
}) => {
  const {
    title,
    teaser,
    date,
    image,
  } = useMemo(
    () => blogEntry.toJSON(),
    [blogEntry]
  )

  const slug = useMemo(
    () => blogEntry.getSlugString(),
    [blogEntry]
  )

  return (
    <Link href={`/blog/${slug}`} title={`title`} className={Styles.link}>
      <article>
        <div className={Styles.imageContainer}>
          <Image
            src={image}
            alt={title}
            layout="fill"
            objectFit="cover"
            placeholder="blur"
          />
        </div>
        <h3 className="headline">{title}</h3>
        <p className="date">
          {format(new Date(date), 'dd.MM.yyyy')}
        </p>
        <p className="teaser text-truncate">
          {teaser}
        </p>
        <div className="text-end">
          <Button size="small">
            mehr&nbsp;<HiChevronDoubleRight />
          </Button>
        </div>
      </article>
    </Link>
  )
}

export default BlogListItem
