interface MessagesType {
  [key: string]: string;
}

const MESSAGES: MessagesType = {
  'rate-limited': `Captcha-Fehler (rate-limited) - Bitte melden Sie sich direkt an "info@frauencoach.jetzt".`,
  'network-error': `Verbindungsfehler - Bitte versuche Sie es erneut. Sollte es nicht funktionieren, wenden Sie sich bitte direkt an "info@frauencoach.jetzt".`,
  'invalid-data': `Captcha-Fehler (invalid-data) - Bitte melden Sie sich direkt an "info@frauencoach.jetzt".`,
  'challenge-error': `Captcha-Fehler (challenge-error) - Bitte melden Sie sich direkt an "info@frauencoach.jetzt".`,
  'challenge-closed': `Captcha-Fehler (challenge-closed) - Bitte melden Sie sich direkt an "info@frauencoach.jetzt".`,
  'challenge-expired': `Bitte versuche es erneut.`,
  'missing-captcha': `Captcha-Fehler (missing-captcha) - Bitte melden Sie sich direkt an "info@frauencoach.jetzt".`,
  'invalid-captcha-id': `Captcha-Fehler (invalid-captcha-id) - Bitte melden Sie sich direkt an "info@frauencoach.jetzt".`,
  'internal-error': `Captcha-Fehler (internal-error) - Bitte melden Sie sich direkt an "info@frauencoach.jetzt".`,
}

export default MESSAGES
