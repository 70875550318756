import {
  FC,
  TextareaHTMLAttributes,
  useMemo,
  useCallback,
  useState,
} from 'react'
import { useField } from 'formik'
import classnames from 'classnames'
import { isPresent } from 'utils'
import Wrapper from '../Wrapper'
import Styles from './Input.module.sass'

interface InputProps extends TextareaHTMLAttributes<HTMLTextAreaElement> {
  /**
   * Field label
   */
  label?: string | null,

  /**
   * Field is required
   */
  required?: boolean,

  /**
   * Classes for the wrapper
   */
  wrapperClassName?: string,
}

const Textarea: FC<InputProps> = ({
  label,
  name,
  required = false,
  disabled = false,
  className,
  wrapperClassName,
  ...restProps
}) => {
  const [field, meta] = useField(name || '')
  const [didFocus, setDidFocus] = useState(false)
  const handleFocus = useCallback(
    () => setDidFocus(true),
    []
  )
  const showFeedback =
    (!!didFocus && (`${field.value as string}`)?.trim()?.length > 0) || meta.touched

  const textareaClasses = useMemo(() => classnames({
    [Styles.textarea]: true,
    [Styles.textareaError]: showFeedback && meta.error,
    [Styles.textareaDisabled]: disabled,
    [className || '']: isPresent(className),
  }), [className, disabled, meta.error, showFeedback])

  return name ? (
    <Wrapper
      value={field?.value}
      label={label}
      required={required}
      disabled={disabled}
      meta={meta}
      showFeedback={showFeedback}
      className={wrapperClassName}
    >
      <textarea
        className={textareaClasses}
        onFocus={handleFocus}
        {...restProps}
        {...field}
      />
    </Wrapper>
  ) : null
}

export default Textarea
