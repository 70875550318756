import { FC, ReactNode, useMemo } from 'react'
import {
  BsCheck2Circle,
  BsDot,
  BsQuestionCircle,
} from 'react-icons/bs'
import classnames from 'classnames'
import Styles from './UnorderedList.module.sass'

interface UnorderedListProps {
  /**
   * Pass through ul className
   */
  className?: string;

  /**
   * Pass through li className(s)
   */
  liClassName?: string;

  /**
   * List style
   */
  type?: 'default' | 'question' | 'check'

  /**
   * Items to be rendered as li
   */
  items: ReactNode[];
}

const UnorderedList: FC<UnorderedListProps> = ({
  className,
  liClassName,
  type = 'default',
  items,
}) => {
  const ulClasses = useMemo(() => classnames({
    [Styles.ul]: true,
    [Styles.ulQuestion]: type === 'question',
    [Styles.ulCheck]: type === 'check',
    [className || '']: true,
  }), [className, type])

  const liClasses = useMemo(() => classnames({
    [liClassName || '']: true,
  }), [liClassName])

  const Icon = useMemo(() => {
    if (type === 'question') return BsQuestionCircle
    if (type === 'check') return BsCheck2Circle
    return BsDot
  }, [type])

  return (
    <ul className={ulClasses}>
      {items.map((item, index) => (
        <li key={index} className={liClasses}>
          <Icon className="li-icon" />
          {item}
        </li>
      ))}
    </ul>
  )
}

export default UnorderedList
