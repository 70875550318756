import {
  FC,
  InputHTMLAttributes,
  useMemo,
} from 'react'
import { useField } from 'formik'
import {
  BsSquare,
  BsCheckSquareFill,
} from 'react-icons/bs'
import classnames from 'classnames'
import Styles from './Checkbox.module.sass'

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
  * Field label
  */
  label?: string | null,

  /**
   * Field is required
   */
  required?: boolean,

  /**
   * Classes for the wrapper
   */
  wrapperClassName?: string,
}

const Checkbox: FC<CheckboxProps> = ({
  label,
  value,
  name,
  required = false,
  disabled = false,
  className,
  wrapperClassName,
  ...restProps
}) => {
  const [field, meta] = useField(name || '')

  const wrapperClasses = useMemo(() => classnames({
    [Styles.wrapper]: true,
    [Styles.wrapperDisabled]: disabled,
    [wrapperClassName || '']: true,
  }), [disabled, wrapperClassName])

  return name ? (
    <div className={wrapperClasses}>
      <label className={Styles.label}>
        {field?.value === true ? (
          <BsCheckSquareFill />
        ) : (
          <BsSquare />
        )}
        <input
          type="checkbox"
          disabled={disabled}
          {...restProps}
          {...field}
        />
        {label}
      </label>
      {meta.error && (
        <div className={Styles.error}>{meta.error}</div>
      )}
    </div>
  ) : null
}

export default Checkbox
