import { FC, ReactNode, useMemo } from 'react'
import classnames from 'classnames'
import Styles from './Alert.module.sass'

interface AlertProps {
  type?: 'default' | 'error' | 'success';
  className?: string;
  children?: ReactNode;
}

const Alert: FC<AlertProps> = ({
  type = 'default',
  className,
  children,
}) => {
  const classes = useMemo(() => classnames({
    [Styles.alert]: true,
    [Styles.alertError]: type === 'error',
    [Styles.alertSuccess]: type === 'success',
    [className || '']: true,
  }), [className, type])

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default Alert
