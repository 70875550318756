import { FC, ReactNode } from 'react'
import Styles from './Blockquote.module.sass'

export interface BlockquoteProps {
  caption?: ReactNode;
  children?: ReactNode;
}

const Blockquote: FC<BlockquoteProps> = ({
  caption,
  children,
}) => (
  <figure className={Styles.figure}>
    <blockquote>
      {children}
    </blockquote>
    {!!caption && (
      <figcaption>
        {caption}
      </figcaption>
    )}
  </figure>
)

export default Blockquote
