import { FC } from 'react'
import Styles from './LoadingIndicator.module.sass'

const LoadingIndicator: FC<unknown> = () => (
  <div className={Styles.ellipsis}>
    <div></div>
    <div></div>
    <div></div>
    <div></div>
  </div>
)

export default LoadingIndicator
