import type { BlogEntry } from 'BlogEntries'
import last from 'lodash/last'
import reduce from 'lodash/reduce'
import { FC, useMemo } from 'react'
import { BlogListItem, Col, Row } from 'atoms'

export interface BlogListProps {
  blogEntries?: BlogEntry[];
}

const BlogList: FC<BlogListProps> = ({
  blogEntries = []
}) => {
  const rows = useMemo(
    () => reduce<BlogEntry, BlogEntry[][]>(
      blogEntries,
      (memo, entry) => {
        if ((last(memo) || []).length >= 3) {
          memo.push([])
        }
        (last(memo) || []).push(entry)
        return memo
      },
      [[]]
    ),
    [blogEntries]
  )

  return (
    <>
      {rows.map((entries, index) => (
        <Row
          key={index}
          className="align-items-stretch"
        >
          {entries.map((entry) => (
            <Col
              xs={12}
              lg={4}
              key={entry.getSlugString()}
              className="d-flex align-items-stretch"
            >
              <BlogListItem blogEntry={entry} />
            </Col>
          ))}
        </Row>
      ))}
    </>
  )
}

export default BlogList
