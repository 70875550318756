import { FC, ReactNode, useMemo } from 'react'
import classnames from 'classnames'
import Styles from './CardContent.module.sass'

interface CardContentProps {
  className?: string,
  first?: boolean,
  last?: boolean,
  children: ReactNode,
}

const CardContent: FC<CardContentProps> = ({
  className,
  first = false,
  last = false,
  children,
}) => {
  const classes = useMemo(() => classnames({
    'card-content': true,
    [Styles.cardContent]: true,
    [Styles.first]: first,
    [Styles.last]: last,
    [className || '']: true,
  }), [className, first, last])

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default CardContent
