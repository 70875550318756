import kebabCase from 'lodash/kebabCase'
import Link from 'next/link'
import { FC } from 'react'

import Styles from './Tags.module.sass'

export interface TagsProps {
  className?: string;
  tags?: string[];
}

const Tags: FC<TagsProps> = ({
  className,
  tags = [],
}) => (
  <ul className={`${Styles.tags} ${className || ''}`}>
    {tags.map((t, i) => (
      <li key={i}>
        <Link href={`/blog/tags/${kebabCase(t)}`}>
          {t}
        </Link>
      </li>
    ))}
  </ul>
)

export default Tags
