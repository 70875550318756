import dynamic from 'next/dynamic'
import { LoadingIndicator } from 'atoms'

const DynamicMap = dynamic(
  () => import('./Map'),
  {
    ssr: false,
    loading: () => (<LoadingIndicator />)
  }
)

export default DynamicMap
