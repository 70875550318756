import { FC, ReactNode } from 'react'

const PREVIEW = process.env.ENVIRONMENT === 'preview'
const ENABLED = !PREVIEW || process.env.PREVIEW_ENABLED === 'true'

interface PreviewWrapperProps {
  children: ReactNode;
}

const PreviewWrapper: FC<PreviewWrapperProps> = ({
  children
}) => ENABLED ? (<>{children}</>) : null

export default PreviewWrapper
