import 'styles/globals.sass'
import 'styles/icons.sass'

import { CookieConsent } from 'atoms'
import PageContentMaintenance from 'pageContent/Maintenance'
import PreviewWrapper from 'services/PreviewWrapper'

import type { AppProps } from 'next/app'
const MAINTENANCE = process.env.MAINTENANCE === 'true'

function MyApp({ Component, pageProps }: AppProps) {
  return MAINTENANCE ? (
    <PageContentMaintenance />
  ) : (
    <PreviewWrapper>
      <CookieConsent />
      <Component {...pageProps} />
    </PreviewWrapper>
  )
}

export default MyApp
