import {
  FC,
  ReactNode,
  useMemo,
} from 'react'
import { FieldMetaProps } from 'formik'
import classnames from 'classnames'
import { isPresent } from 'utils'
import Styles from './Wrapper.module.sass'

interface WrapperProps {
  required?: boolean,
  disabled?: boolean,
  label?: string | null,
  meta: FieldMetaProps<unknown>,
  showFeedback: boolean,
  children: ReactNode,
  className?: string | null,
  value?: unknown | null,
}

const Wrapper: FC<WrapperProps> = ({
  label,
  meta,
  required = false,
  disabled = false,
  showFeedback = false,
  value = null,
  className,
  children,
}) => {
  const containerClasses = useMemo(() => classnames({
    [Styles.wrapper]: true,
    [Styles.wrapperWithValue]: isPresent(value),
    [Styles.wrapperDisabled]: disabled,
    [className || '']: true,
  }), [className, disabled, value])

  return (
    <div className={containerClasses}>
      <label>
        {children}
        <span>
          {label}
          {required && (<>&nbsp;*</>)}
        </span>
      </label>
      {showFeedback ? (
        <div className={Styles.error}>{meta.error}</div>
      ) : null}
    </div>
  )
}

export default Wrapper
