import classnames from 'classnames'
import { FC, ReactNode, useMemo } from 'react'

import Styles from './Section.module.sass'

interface SectionProps {
  id?: string,
  className?: string,
  background?: 'default' | 'rose' | 'vintage' | 'coach' | 'white'

  /**
   * Content
   */
  children?: ReactNode,
}

const Section: FC<SectionProps> = ({
  id,
  className,
  background = 'default',
  children,
}) => {
  const sectionClasses = useMemo(() => classnames({
    [Styles.section]: true,
    [Styles.sectionRose]: background === 'rose',
    [Styles.sectionVintage]: background === 'vintage',
    [Styles.sectionCoach]: background === 'coach',
    [Styles.sectionWhite]: background === 'white',
    [className || '']: true,
  }), [background, className])

  return (
    <section id={id} className={sectionClasses}>
      {children}
    </section>
  )
}

export default Section
