import { FC, ReactNode } from 'react'
import ReactModal, { Props as ReactModalProps } from 'react-modal'
import ModalHeader from './ModalHeader'
import Styles from './Modal.module.sass'

ReactModal.defaultStyles.overlay = {}
ReactModal.defaultStyles.content = {}

interface ModalProps extends ReactModalProps {
  showHeader: boolean;
  headerTitle?: ReactNode | string | null;
  onClose?: () => void;
}

const Modal: FC<ModalProps> = ({
  headerTitle = null,
  children = null,
  showHeader = false,
  className = null,
  onClose = () => undefined,
  ...restProps
}) => (
  <ReactModal
    {...restProps}
    className={`${Styles.modal} ${(className || '') as string}`}
    onRequestClose={onClose}
  >
    {showHeader && (
      <ModalHeader
        title={headerTitle}
        onClickClose={onClose}
      />
    )}
    <div className={Styles.modalContent}>
      {children}
    </div>
  </ReactModal>
)

export default Modal
