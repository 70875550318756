import { FC } from 'react'
import Image from 'next/image'
import { Helmet } from 'react-helmet'
import { Section, Card, CardContent } from 'atoms'
import LogoImage from 'assets/images/logo-240x47.jpg'
import Styles from './Maintenance.module.sass'

const PageContentMaintenance: FC<unknown> = () => (
  <>
    <Helmet>
      <title>FrauenCoach | Wartung</title>
      <meta content='noindex,nofollow' name='robots' />
    </Helmet>
    <Section background='rose' className={Styles.section}>
      <Card>
        <CardContent className={Styles.cardContent}>
          <div className={Styles.logoContainer}>
            <Image
              src={LogoImage}
              alt="FrauenCoach"
            />
          </div>
          <hr />
          <h1 className="text-center">
            Unsere Seite wird aktualisiert
          </h1>
          <p className="text-center">
            Wir sind bald wieder für Sie da
          </p>
        </CardContent>
      </Card>
    </Section>
  </>
)

export default PageContentMaintenance
