import isFunction from 'lodash/isFunction'
import isNumber from 'lodash/isNumber'
import isNaN from 'lodash/isNaN'
import {
  FC,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import classnames from 'classnames'
import { CollapsibleItemProps } from '../Types'
import useCollabsibleItem from '../useCollabsibleItem'
import Styles from './CollapsibleItem.module.sass'

const CollapsibleItem: FC<CollapsibleItemProps> = ({
  groupId,
  id,
  label,
  content,
  className,
}) => {
  const contentRef = useRef<HTMLDivElement>(null)
  const [contentHeight, setContentHeight] = useState<null | number>(null)
  const {
    id: itemId,
    collapsed,
    toggle,
  } = useCollabsibleItem(groupId, id)

  const contentHeightCalc = useMemo(() => {
    if (contentHeight === null) return 'auto'
    if (contentHeight === undefined) return 'auto'
    return collapsed ? '0px' : `${contentHeight}px`
  }, [collapsed, contentHeight])

  const wrapperClasses = useMemo(() => classnames({
    [Styles.item]: true,
    [className || '']: true,
  }), [className])

  useEffect(() => {
    if (!contentRef.current?.clientHeight) return

    setContentHeight(contentRef.current?.clientHeight)
  }, [])

  //
  // Patch: browser does not detect paddings. So after
  // uncollapsing, we need to recalculate
  useEffect(() => {
    const h = contentRef.current?.clientHeight

    if (!isNumber(h) || isNaN(h)) return
    if (collapsed) return

    // If there were any changes on the DOM resulting in
    // a different height, we want to make sure the height
    // is adjusted. We do this by setting the value to 'auto'
    // and afterwards to the new, calculated height again.
    setTimeout(() => {
      if (!isFunction(setContentHeight)) return

      setContentHeight(null)

      setTimeout(() => {
        if (!isFunction(setContentHeight)) return

        setContentHeight(contentRef.current?.clientHeight as number)
      }, 150)
    }, 400)
  }, [collapsed])

  return (
    <div
      className={wrapperClasses}
      key={itemId}
    >
      <input
        id={itemId}
        className={Styles.itemToggle}
        type="checkbox"
        checked={!collapsed}
        onChange={(_e) => toggle()}
      />
      <label
        htmlFor={itemId}
        className={Styles.itemLabel}
      >
        {label}
      </label>
      <div
        ref={contentRef}
        className={Styles.itemContent}
        style={{ height: contentHeightCalc }}
      >
        {content}
      </div>
    </div>
  )
}

export default CollapsibleItem
