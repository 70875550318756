import {
  ForwardRefRenderFunction,
  forwardRef,
  LegacyRef,
  useImperativeHandle,
  useMemo,
  useRef,
} from 'react'
import { useField } from 'formik'
import HCaptcha from '@hcaptcha/react-hcaptcha'
import MESSAGES from './Messages'
import Styles from './Captcha.module.sass'

const SITEKEY = process.env.NEXT_PUBLIC_HCAPTCHA_SITEKEY || ''

type CaptchaValidateResponse = {
  valid: boolean,
  token?: string | null,
  key?: string | null,
  error?: string | null,
}

export interface CaptchaHandle {
  validate?: () => Promise<CaptchaValidateResponse>,
}

export interface CaptchaProps extends CaptchaHandle {
  name: string;
}

const Captcha: ForwardRefRenderFunction<CaptchaHandle, CaptchaProps> = ({
  name,
}, ref) => {
  const captchaRef = useRef<HCaptcha>()
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_field, meta] = useField(name || '')
  const captchaSize = useMemo(() => meta?.error
    ? 'normal'
    : 'invisible'
  , [meta?.error])

  useImperativeHandle(ref, () => ({
    validate: async () => {
      try {
        const {
          response,
          key,
        } = (await captchaRef.current?.execute({ async: true })) || {}

        return {
          valid: true,
          token: response,
          key,
          error: null,
        }
      } catch (error) {
        if (typeof error !== 'string') {
          throw error
        }

        return {
          valid: false,
          token: null,
          key: null,
          error: MESSAGES[error]
        }
      }
    }
  }))

  return (
    <>
      <HCaptcha
        sitekey={SITEKEY}
        ref={captchaRef as LegacyRef<HCaptcha>}
        languageOverride="de"
        theme="light"
        size={captchaSize}
      />
      {meta?.error && (
        <div className={Styles.error}>
          {meta.error}
        </div>
      )}
    </>
  )
}

export default forwardRef(Captcha)
