import { FC, ReactNode, useMemo } from 'react'
import classnames from 'classnames'
import { isPresent } from 'utils'

interface ContainerProps {
  fluid?: boolean;
  sm?: boolean;
  md?: boolean;
  lg?: boolean;
  xl?: boolean;
  xxl?: boolean;
  children?: ReactNode;
  className?: string;
}

const Container: FC<ContainerProps> = ({
  fluid = false,
  sm = false,
  md = false,
  lg = false,
  xl = false,
  xxl = false,
  children,
  className,
}) => {
  const style = useMemo(() => {
    if (fluid) return 'fluid'
    if (sm) return 'sm'
    if (md) return 'md'
    if (lg) return 'lg'
    if (xl) return 'xl'
    if (xxl) return 'xxl'

    return 'default'
  }, [fluid, lg, md, sm, xl, xxl])

  const classes = useMemo(() => classnames({
    'container': style === 'default',
    'container-fluid': style === 'fluid',
    'container-sm': style === 'sm',
    'container-md': style === 'md',
    'container-lg': style === 'lg',
    'container-xl': style === 'xl',
    'container-xxl': style === 'xxl',
    [className || '']: isPresent(className),
  }), [className, style])

  return (
    <div className={classes}>
      {children}
    </div>
  )
}

export default Container
