import { FC, ReactNode, useMemo } from 'react'
import classnames from 'classnames'
import Styles from './CardImage.module.sass'

interface CardImageProps {
  first?: boolean,
  last?: boolean,
  image: ReactNode,
  labelColor?: 'default' | 'brown' | 'blue' | 'green',
  label?: string,
  height?: 'auto' | null | number,
}

const CardImage: FC<CardImageProps> = ({
  first = false,
  last = false,
  image = null,
  labelColor = 'default',
  label = null,
  height = 'auto',
}) => {
  const classes = useMemo(() => classnames({
    'card-image': true,
    [Styles.cardImage]: true,
    [Styles.first]: first,
    [Styles.last]: last,
  }), [first, last])

  const labelClasses = useMemo(() => classnames({
    'card-image-label': true,
    [Styles.label]: true,
    [Styles.labelBrown]: labelColor === 'brown',
    [Styles.labelBlue]: labelColor === 'blue',
    [Styles.labelGreen]: labelColor === 'green',
  }), [labelColor])

  const imageContainerHeight = useMemo(() => {
    if (!height || height === 'auto') return 'auto'
    return `${height}px`
  }, [height])

  return (
    <div className={classes}>
      <div
        className={Styles.imageWrapper}
        style={{ height: imageContainerHeight }}
      >
        {image}
      </div>
      {label && (
        <div className={labelClasses}>
          {label}
        </div>
      )}
    </div>
  )
}

export default CardImage
